<template>
  <v-form ref="form" @submit.prevent="validateBeforeSubmit">
  
   <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>Subject of Allegation - Worker</v-card-title>

      

                <v-card-text>
                  <v-col>
                    <v-select
                      v-model="vm.title"
                      :items="title"
                      label="Title"
                      v-validate="'required'"
                      data-vv-as="Title"
                      name="title"
                      :error-messages="errors.collect('title')"
                    ></v-select>
                  </v-col>

                  <v-col>
                    <v-text-field
                      v-model="vm.firstName"
                      label="First name"
                      v-validate="'required'"
                      data-vv-as="First name"
                      name="firstName"
                      :error-messages="errors.collect('firstName')"
                    ></v-text-field>
                  </v-col>

                  <v-col>
                    <v-text-field
                      v-model="vm.lastName"
                      label="Last name"
                      v-validate="'required'"
                      data-vv-as="Last name"
                      name="lastName"
                      :error-messages="errors.collect('lastName')"
                    ></v-text-field>
                  </v-col>
<v-row v-if="this.currentIncident.incidentOverview.incidentNotificationType == '5 Day Notification'">
                  <v-col>
                    <v-text-field
                      v-model="vm.ndisWorkerScreeningNumber"
                      label="NDIS worker screening number"
                      v-validate="'required'"
                      data-vv-as="NDIS worker screening number"
                      name="ndisWorkerScreeningNumber"
                      :error-messages="errors.collect('ndisWorkerScreeningNumber')"
                    ></v-text-field>
                  </v-col>
</v-row>
                  <v-col >
                    <v-text-field
                      v-model="vm.positionAtTimeOfAllegation"
                      label="Position at time of allegation"
                      v-validate="'required'"
                      data-vv-as="Position at time of allegation"
                      name="positionAtTimeOfAllegation"
                      :error-messages="errors.collect('positionAtTimeOfAllegation')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-select
                      v-model="vm.gender"
                      :items="gender"
                      label="Gender"
                      v-validate="'required'"
                      data-vv-as="gender"
                      name="gender"
                      :error-messages="errors.collect('gender')"
                    ></v-select>
                  </v-col>

                  <v-col >
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="vm.dateOfBirth"
                      
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="vm.dateOfBirth"
                          label="Date Of Birth"
                          readonly
                          v-on="on"
                          v-validate="'required'"
                          data-vv-as="dateOfBirth"
                          name="dateOfBirth"
                          :error-messages="errors.collect('dateOfBirth')"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="vm.dateOfBirth"
                        no-title
                        scrollable
                        color="green lighten-1"
                        dark
                        @click:date="$refs.menu.save(vm.dateOfBirth)"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col >
                    <v-text-field
                      v-model="vm.phoneNumber"
                      label="Phone number"
                      v-validate="'required'"
                      data-vv-as="Phone number"
                      name="phoneNumber"
                      :error-messages="errors.collect('phoneNumber')"
                    ></v-text-field>
                  </v-col>

                  <v-col >
                    <v-text-field
                      v-model="vm.email"
                      label="E-mail address"
                      v-validate="'required|email'"
                      data-vv-as="Email address"
                      name="email"
                      :error-messages="errors.collect('email')"
                    ></v-text-field>
                  </v-col>
                  
<template v-if="this.currentIncident.incidentOverview.incidentNotificationType == '5 Day Notification'">
            <v-col >
              <v-select
                v-model="vm.employmentStatusAtTime"
                :items="employmentStatusAtTime"
                label="Employment status with the provider at the time of allegation"
                v-validate="'required'"
                data-vv-as="employment status"
                name="employmentStatusAtTime"
                :error-messages="errors.collect('employmentStatusAtTime')"
              ></v-select>
            </v-col>


  <v-col >
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="vm.employmentStartDate"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="vm.employmentStartDate"
                    label="Employment Start Date"
                    readonly
                    v-on="on"
                    v-validate="'required'"
                    data-vv-as="Emplyoment Start Date"
                    name="employmentStartDate"
                    :error-messages="errors.collect('employmentStartDate')"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="vm.employmentStartDate"
                  no-title
                  scrollable
                  color="green lighten-1"
                  dark
                  @click:date="$refs.menu2.save(vm.employmentStartDate)"
                >
                </v-date-picker>
              </v-menu>
            </v-col>

 <v-col cols="12">
          <v-radio-group
            v-model="vm.workerAwareOfAllegation"
            label="Is the person aware of the allegation made against them?"
            v-validate="'required'"
            data-vv-as="Is a worker aware"
            name="workerAwareOfAllegation"
            :error-messages="errors.collect('workerAwareOfAllegation')"
          >
            <v-radio label="Yes" v-bind:value="true"></v-radio>
            <v-radio label="No" v-bind:value="false"></v-radio>
          </v-radio-group>
      </v-col>
         
<template v-if="vm.workerAwareOfAllegation == true">

  <v-col >
              <v-menu
                ref="menu3"
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="vm.dateWorkerMadeAware"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="vm.dateWorkerMadeAware"
                    label="Date Worker was made aware"
                    readonly
                    v-on="on"
                    v-validate="'required'"
                    data-vv-as="Date Worker was made aware"
                    name="dateWorkerMadeAware"
                    :error-messages="errors.collect('dateWorkerMadeAware')"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="vm.dateWorkerMadeAware"
                  no-title
                  scrollable
                  color="green lighten-1"
                  dark
                  @click:date="$refs.menu3.save(vm.dateWorkerMadeAware)"
                >
                </v-date-picker>
              </v-menu>
            </v-col>

<v-col >
              <v-text-field
                v-model="vm.whoInformedWorker"                
                label="Who informed the worker?"
                v-validate="'required'"
                data-vv-as="Who informed the worker"
                name="whoInformedWorker"
                :error-messages="errors.collect('whoInformedWorker')"
              ></v-text-field>
            </v-col>

</template>

<template v-if="vm.workerAwareOfAllegation == false">
<v-col >
              <v-text-field
                v-model="vm.workerNotAwareReason"
                
                label="If the person is not aware of the allegation, why havent they been made aware?"
                v-validate="'required'"
                data-vv-as="person not aware reason"
                name="workerNotAwareReason"
                :error-messages="errors.collect('workerNotAwareReason')"
              ></v-text-field>
            </v-col>
</template>





  <v-col>
          <v-text-field
            v-model="vm.workWithChildrenCheckNumber"
            label="Working with Children/Vulnerable people check number"
            v-validate="'required'"
            data-vv-as="Working with Children/Vulnerable people check number"
            name="workWithChildrenCheckNumber"
            :error-messages="errors.collect('workWithChildrenCheckNumber')"
          ></v-text-field>
        </v-col>


 <v-col>
          <v-select
            v-model="vm.stateCheckObtained"
            :items="stateList"
            label="State where the check was obtained"
            v-validate="'required'"
            data-vv-as="State where the check was obtained"
            name="stateCheckObtained"
            :error-messages="errors.collect('stateCheckObtained')"
          ></v-select>
        </v-col>



  <v-col>
          <v-text-field
            v-model="vm.driversLicenseNumber"
            label="Drivers license number"
            v-validate="'required'"
            data-vv-as="Drivers license number"
            name="driversLicenseNumber"
            :error-messages="errors.collect('driversLicenseNumber')"
          ></v-text-field>
        </v-col>


 <v-col>
          <v-select
            v-model="vm.stateLicenseObtained"
            :items="stateList"
            label="State where license was obtained"
            v-validate="'required'"
            data-vv-as="State where license was obtained"
            name="stateLicenseObtained"
            :error-messages="errors.collect('stateLicenseObtained')"
          ></v-select>
        </v-col>


  <v-col>
          <v-text-field
            v-model="vm.passportNumber"
            label="Passport number"
            v-validate="'required'"
            data-vv-as="Passport number"
            name="passportNumber"
            :error-messages="errors.collect('passportNumber')"
          ></v-text-field>
        </v-col>

</template>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="close">Cancel</v-btn>
            <v-btn color="primary" type="submit">Save</v-btn>
          </v-card-actions>
        </v-card>
  </v-form>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);
export default {
  name: "notes-form",

  data: () => ({
    vm: {},
    dobMenu: null,
    menu: null,
    menu2: null, 
    menu3: null,
    form: {},
     gender: ["Male", "Female", "Indeterminate", "Intersex", "Unspecified"],
    stateList: ["NSW", "Queensland","South Australia", "Western Australia","Victoria", "Tasmania", "Northern Territory", "ACT"],
    employmentStatusAtTime: [
      "Permanent - Full time",
      "Permanent - Part time",
      "Temp - Full time",
      "Temp - Part time",
      "Casual",
      "Contracted labour hire",
      "Volunteer",
      "Contractor"
    ],
    headers: [
      { text: "First Name", value: "subjectFirstName" },
      { text: "Last Name", value: "subjectLastName" },
      { text: "Type", value: "subjectType" }
    ],
    title: ["Mr", "Mrs", "Ms", "Miss", "Dr"]
  }),
  watch: {
    subject(value) {
      if (value !== null && value !== undefined) {
        this.init();
      }
    }
  },
  mounted() {
    this.init();
  },
  props: {
    subject: Object,
    currentIncident: Object
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      if (this.subject === undefined || this.subject === null) {
        this.vm = {};
      } else {
        this.vm = this.subject;
      }

      if (
        this.currentIncident.subjectsOfAllegationWorker.subjects === undefined ||
        this.currentIncident.subjectsOfAllegationWorker.subjects === null
      ) {
        this.currentIncident.subjectsOfAllegationWorker.subjects = [];
      }
    },

    close() {
      this.$emit("subject-closed");
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit("subject-added", this.vm);
        }
      });
    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4 {
  color: green;
  font-weight: bold;
}
</style>

